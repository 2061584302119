import React from 'react';
import structure from '../../db/homePageStructure.json';
import {Link} from "react-router-dom";

const HomeDesktop = ({br, projects}) => {

    return (
        <div className="grid">
            {
                Array.from({length: structure.length}, (_, x) => {
                    return (
                        <div key={x} className="d-flex justify-content-center">
                            {
                                Array.from({length: structure[x].column}, (_, y) => {
                                    br -= 1;
                                    return (
                                        <Link key={br} to={"/project/" + projects[br]?.title.replaceAll(" ", "-")}
                                              className={projects[br]?.visibility ? 'invisible' : 'visible'}>
                                            <figure className="effect-ming mx-2">
                                                <img src={projects[br]?.projectLogo} alt={projects[br]?.title}/>
                                                <figcaption>
                                                    <p className="w-100 text-lowercase"
                                                       style={{color: "#f7f7f7"}}>{projects[br]?.title}</p>
                                                </figcaption>
                                            </figure>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    );
}

export default HomeDesktop;