import React from 'react';
import contactDB from '../../db/contact.json';
import './contact.scss';

const Contact = () => {
    const [contactInfo] = React.useState(contactDB);

    return (
        <div className="contact container" style={{height: "90vh"}}>
            <div className="row h-100">
                <div className="contact-text d-block w-auto">
                    <div className="contact-title-wrapper d-flex align-items-center h-50">
                        <span className="contact-title" style={{fontSize: "7em"}}>
                            <img style={{width: "0.65em"}} src="https://i.postimg.cc/VvBWdWr0/Logo-white.png" alt="white-logo"/>
                            ay hello.
                        </span>
                    </div>
                    <div className="d-flex align-items-end h-50">
                        <div className="w-100 text-center text-md-end mb-5">
                            <div style={{color: "#F6F6F6", letterSpacing: "1px"}}>{contactInfo.fullName || ""}</div>
                            <div style={{color: "#F6F6F6", letterSpacing: "1px"}}>{contactInfo.email || ""}</div>
                            <div style={{color: "#F6F6F6", letterSpacing: "1px"}}>{contactInfo.phone || ""}</div>
                            <div>
                                {
                                    contactInfo?.behance &&
                                    <a style={{fontSize: "3em"}}
                                       className="text-white me-3"
                                       rel="noreferrer"
                                       href={contactInfo.behance} target="_blank">
                                        <i className="fab fa-behance"></i>
                                    </a>
                                }
                                {
                                    contactInfo?.instagram &&
                                    <a style={{fontSize: "3em"}}
                                       className="text-white me-3"
                                       rel="noreferrer"
                                       href={contactInfo.instagram} target="_blank">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                }
                                {
                                    contactInfo?.linkedin &&
                                    <a style={{fontSize: "3em"}}
                                       className="text-white"
                                       rel="noreferrer"
                                       href={contactInfo.linkedin} target="_blank">
                                        <i className="fab fa-linkedin"></i>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
