import React from 'react';
import './loading.scss';

const Loading = () => {
    return (
        <div className="loading-animation container">
            <div className="cube">
                <div className="half1">
                    <div className="side s1"></div>
                    <div className="side s2"></div>
                    <div className="side s5"></div>
                </div>
                <div className="half2">
                    <div className="side s3"></div>
                    <div className="side s4"></div>
                    <div className="side s6"></div>
                </div>
            </div>
        </div>
    );
}

export default Loading;