import React from 'react';
import {NavLink, useLocation} from "react-router-dom";
import projects from '../../db/projects.json';
import "./header.css";

const Header = ({setIsActiveFilter, setHandleSelectedFilter}) => {
    const location = useLocation();

    const burgerButtonRef = React.useRef(null);
    const projectsFilterButtonRef = React.useRef(null);
    const [selectedFilter, setSelectedFilter] = React.useState('');
    const [isRedirectToHome, setIsRedirectToHome] = React.useState(false);
    const [categories] = React.useState(Array.from(
        new Set(projects.flatMap(obj => obj.category))
    ).sort());

    React.useEffect(() => {
        setHandleSelectedFilter(selectedFilter);
    }, [selectedFilter]);

    React.useEffect(() => {
        if(isRedirectToHome){
            projectsFilterButtonRef.current.click()
        }
        setIsRedirectToHome(false);
    }, [isRedirectToHome]);

    return (
        <header>
            <nav className="main-header navbar pb-0">
                <div className="container d-none d-md-flex">
                    <div className="col-4">
                        <div className="d-flex">
                            <NavLink to="/">
                                <img className="me-3" role="button" src={"https://i.postimg.cc/VvBWdWr0/Logo-white.png"}
                                     alt="logo" width="40" height="40"/>
                            </NavLink>
                            <NavLink to="/" className="logo-text">STOICHEVSKI</NavLink>
                        </div>
                    </div>

                    {
                        location.pathname === "/" ?
                            <div className="col-4 d-flex justify-content-center">
                                <span className="navbar-brand text-lowercase"
                                      onClick={() => setIsActiveFilter()}>PROJECTS</span>
                            </div>
                            :
                            <div className="col-4 d-flex justify-content-center">
                                <NavLink to="/" className="navbar-brand text-lowercase">PROJECTS</NavLink>
                            </div>
                    }

                    <div className="col-4 d-flex justify-content-end">
                        <NavLink to="/about"
                                 className={`${({isActive}) => (isActive ? "active" : '')} navbar-brand text-lowercase`}>ABOUT</NavLink>
                        <NavLink to="/contact"
                                 className={`${({isActive}) => (isActive ? "active" : '')} navbar-brand text-lowercase`}>CONTACT</NavLink>
                    </div>
                </div>
                <div className="container mobile-header d-md-none px-5 p-2">
                    <div className="a-header d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                            <NavLink to="/">
                                <img className="me-3" role="button" src={"https://i.postimg.cc/VvBWdWr0/Logo-white.png"}
                                     alt="logo" width="40" height="40"/>
                            </NavLink>
                            {/*<NavLink to="/" className="logo-text">STOICHEVSKI</NavLink>*/}
                        </div>
                        <input type="checkbox" name="main-nav" id="main-nav" className="burger-check"/>
                        <label ref={burgerButtonRef} htmlFor="main-nav" className="burger menu collapsed" role="button"
                               data-bs-toggle="collapse" data-bs-target="#navbarToggle"
                               aria-controls="navbarToggle" aria-expanded="false"
                               aria-label="Toggle navigation">
                            <span></span>
                        </label>
                    </div>
                    <div className="collapse w-100" id="navbarToggle">
                        <ul className="p-2 list-unstyled text-center">
                            <li className="mobile-filter">
                                {
                                    location.pathname === "/" ?
                                        <>
                                            <span ref={projectsFilterButtonRef} className="m-0 navbar-brand text-lowercase mobile-line-height"
                                                  data-bs-toggle="collapse"
                                                  data-bs-target="#projectsToggle" aria-controls="projectsToggle"
                                                  aria-expanded="false"
                                                  aria-label="Toggle navigation">PROJECTS</span>
                                            <ul id="projectsToggle" className="p-2 list-unstyled text-center collapse">
                                                <li className="d-flex justify-content-center">
                                                    <span role="button"
                                                          className={`mx-2 ${selectedFilter === "" && "active"} filter-span text-lowercase`}
                                                          onClick={() => {
                                                              setSelectedFilter("");
                                                              burgerButtonRef.current.click();
                                                          }}>
                                                        all
                                                    </span>
                                                </li>
                                                {
                                                    categories.map((category, index) => {
                                                        return (
                                                            <li key={index} className="d-flex justify-content-center">
                                                                <span role="button"
                                                                      className={`mx-2 ${selectedFilter === category && "active"} filter-span text-lowercase`}
                                                                      onClick={() => {
                                                                          setSelectedFilter(selectedFilter === category ? '' : category);
                                                                          burgerButtonRef.current.click();
                                                                      }}>
                                                                    {category || "all"}
                                                                </span>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </>
                                        :
                                        <NavLink to="/" onClick={() => setIsRedirectToHome(true)}
                                                 className="m-0 navbar-brand text-lowercase mobile-line-height">PROJECTS</NavLink>
                                }
                            </li>
                            <li>
                                <NavLink to="/about" onClick={() => burgerButtonRef.current.click()}
                                         className="m-0 navbar-brand text-lowercase mobile-line-height">ABOUT</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contact" onClick={() => burgerButtonRef.current.click()}
                                         className="m-0 navbar-brand text-lowercase mobile-line-height">CONTACT</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;