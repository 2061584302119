import React from 'react';
import contactDB from '../../db/contact.json';
import './about.scss';

const About = () => {
    const imageUrl = contactDB.imageLink;

    return (
        <div className="about container" style={{height: "90vh"}}>
            <div className="row h-100">
                <div className="col-xs-12 col-md-5 d-flex justify-content-between flex-column align-items-center">
                    <div className="h-75 d-flex align-items-center">
                        <div className="about-picture"
                             style={{backgroundImage: `url(${imageUrl})`}}>
                        </div>
                    </div>
                    <div>
                        <span style={{fontSize: "7em"}}>about.</span>
                    </div>
                </div>
                <div className="about-text col-xs-12 col-md-7 mb-5 d-flex align-items-end justify-content-end flex-column">
                    <span style={{margin: "15px 0"}}>
                        I am Stole Stoichevski, an architect born in Macedonia. I graduated from
                        St. Cyril and Methodius University in 2016 and earned a master's degree in sustainable
                        architecture and landscape design from Politecnico Di Milano in 2020.
                    </span>
                    <span style={{margin: "15px 0"}}>
                        My career focuses on the interplay between architecture and nature, creating designs
                        that blend art and science for sustainability. I believe that remarkable architecture
                        should be sustainable and collaborative, contributing to both communities and cities.
                        I am passionate about reintroducing nature into our lives to find balance and harmony.
                    </span>
                    <span className="mb-4 mb-md-0">
                        As your architect, I combine bold design, sustainable technology, and a deep understanding
                        of the human experience to create inspiring environments.
                    </span>
                </div>
            </div>
        </div>
    );
}

export default About;
