import React from 'react';
import {useNavigate} from "react-router-dom";

const HomeMobile = ({projects}) => {
    let navigate = useNavigate();
    const filteredProjects = projects.filter(proj => proj.visibility === false);
    const [activeProjects, setActiveProjects] = React.useState({});
    const figureRef = React.useRef(null);

    const checkClick = (project) => {
        if (activeProjects[project.title]) {
            let path = `/project/${project.title.replaceAll(" ", "-")}`;
            navigate(path);
        } else {
            setActiveProjects({
                [project.title]: true
            });
        }
    };

    return (
        <div className="grid">
            <div className="d-flex justify-content-center flex-wrap flex-row">
                {
                    filteredProjects.reverse().map((project, index) => {
                        return (
                            <div key={index} onClick={() => checkClick(project)}>
                                <figure ref={figureRef} className={`${activeProjects[project.title] ? 'effect-ming-mobile' : ''} effect-ming mx-2`}>
                                    <img src={project.projectLogo} alt={project?.title}/>
                                    <figcaption>
                                        <p className="w-100 text-lowercase"
                                           style={{color: "#f7f7f7"}}>{project?.title}</p>
                                    </figcaption>
                                </figure>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default HomeMobile;