import React from "react";
import {Route, Routes} from "react-router-dom";

import Header from "./components/header/header";
import Home from "./pages/home/home";
import About from "./pages/about/about";
import Contact from "./pages/contact/contact";
import Loading from "./components/loading/loading";

import ProjectDetails from "./pages/project-details/project-details";
import {Analytics} from "@vercel/analytics/react";
import './App.css';

function App() {
    const [isActiveFilter, setIsActiveFilter] = React.useState(false);
    const [handleSelectedFilter, setHandleSelectedFilter] = React.useState('');
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 5100)
    }, [])

    return (
        <div className="App">
            <>
                <Header
                    setIsActiveFilter={() => setIsActiveFilter(!isActiveFilter)}
                    setHandleSelectedFilter={(e) => setHandleSelectedFilter(e)}/>
                {
                    loading && <Loading/>
                }
                {
                    !loading &&
                    <Routes>
                        <Route path="/" element={<Home isActiveFilter={isActiveFilter}
                                                       handleSelectedFilter={handleSelectedFilter}/>}></Route>
                        <Route path="/project/:id" element={<ProjectDetails/>}></Route>
                        <Route path="/about" element={<About/>}></Route>
                        <Route path="/contact" element={<Contact/>}></Route>
                    </Routes>
                }
                {
                    <Analytics/>
                }
            </>
        </div>
    );
}

export default App;
