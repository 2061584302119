import React from 'react';
import {useParams} from "react-router-dom";
import projects from '../../db/projects.json';
// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";
// import required modules
import {Navigation, Mousewheel, Keyboard} from 'swiper';

import Loading from "../../components/loading/loading";
// Import Swiper styles
import "swiper/css";

import 'swiper/css/navigation';
import './slider.css';

const ProjectDetails = () => {
    const {id} = useParams();
    const [loading, setLoading] = React.useState(true);
    const [isNextSlide, setIsNextSlide] = React.useState(true);
    const swiperRef = React.useRef(null);

    React.useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1500)
    }, [])

    React.useEffect(() => {
        if (swiperRef.current && !loading) {
            swiperRef.current.swiper.slideTo(0);
        }
    }, [loading]);

    const onSlideChange = () => {
        swiperRef.current.swiper.isEnd ? setIsNextSlide(false) : setIsNextSlide(true);
    };

    return (
        <>
            {
                loading && <Loading/>
            }
            {
                projects.length &&
                <div className="container project-details-desktop" style={{height: "90vh", visibility: loading ? "hidden" : "visible"}}>
                    <Swiper
                        ref={swiperRef}
                        spaceBetween={50} // Adjust the spacing between slides as desired
                        slidesPerView={'auto'} // Show only one slide at a time
                        centeredSlides={true}
                        normalizeSlideIndex={false}
                        navigation={true}
                        mousewheel={true}
                        keyboard={true}
                        initialSlide={0}
                        onSlideChange={onSlideChange}
                        allowSlideNext={isNextSlide}
                        modules={[Navigation, Mousewheel, Keyboard]}
                        style={{height: 'inherit'}} // Set the slider height to 100%
                        className="mySwiper"
                    >
                        {projects.find(proj => proj.title.replaceAll(" ", "-") === id).pictures.map((imageUrl, index) => (
                            <SwiperSlide key={index}>
                                <img src={imageUrl} alt={`Slide ${index}`}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            }
            {
                projects.length &&
                <div className="container d-none project-details-mobile">
                    {projects.find(proj => proj.title.replaceAll(" ", "-") === id).pictures.map((imageUrl, index) => (
                        <img className="w-100 mb-3" key={index} src={imageUrl} alt={`num ${index}`}/>
                    ))}
                </div>
            }
        </>
    )
}

export default ProjectDetails;