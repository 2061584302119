import React from 'react';
import Filters from "../../components/filters/filters";
import projectsDB from '../../db/projects.json';
import HomeDesktop from "./home-desktop";
import HomeMobile from "./home-mobile";

const Home = ({isActiveFilter, handleSelectedFilter}) => {
    let br = projectsDB.length;
    const [projects, setProjects] = React.useState(projectsDB);

    function handleFilter(selectedFilter) {
        const updatedProjects = projects.map(proj => ({
            ...proj,
            visibility: selectedFilter === '' ? false : !proj.category.includes(selectedFilter)
        }));
        setProjects(updatedProjects);
    }

    React.useEffect(() => {
        handleFilter(handleSelectedFilter);
    },[handleSelectedFilter])

    return (
        projects &&
        <>
            <Filters isActiveFilter={isActiveFilter} projects={projects} handleFilter={handleFilter}/>
            <div className="container-fluid">
                <div className="row">
                    <div className="home-desktop">
                        <HomeDesktop br={br} projects={projects}/>
                    </div>
                    <div className="d-none home-mobile">
                        <HomeMobile projects={projects}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;