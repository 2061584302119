import React from 'react';
import './filters.css';

const Filters = ({isActiveFilter, projects, handleFilter}) => {

    const [selectedFilter, setSelectedFilter] = React.useState('');
    const [categories] = React.useState(Array.from(
        new Set(projects.flatMap(obj => obj.category))
    ).sort());

    React.useEffect(() => {
        handleFilter(selectedFilter);
    }, [selectedFilter])

    return (
        <div className="filter d-none d-md-block">
            <nav className="navbar navbar-light p-0">
                <div className="container justify-content-center">
                    <nav>
                        <ul>
                            <li>
                                <span role="button" className={`mx-2 ${selectedFilter === "" && "active"} text-lowercase`}
                                      onClick={() => setSelectedFilter("")}>all</span>
                            </li>
                            {
                                categories.map((category, index) => {
                                    return (
                                        <li key={index}>
                                            <span role="button"
                                                  className={`mx-2 ${selectedFilter === category && "active"} text-lowercase`}
                                                  onClick={() => setSelectedFilter(selectedFilter === category ? '' : category)}>{category || "all"}</span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </nav>
                </div>
            </nav>
        </div>
    );
}

export default Filters;